import { useCallback } from 'react';

import { deploymentUrl } from '@personly/libs-urls';
import { useNotifications } from '@personly/libs-providers';

const useEmailAddresses = () => {
  const { createNotification } = useNotifications();

  const verifyEmailAddress = useCallback(
    async (token, cb) => {
      const url = deploymentUrl('/agon/v1/guest/verifyEmailAddress');

      url.searchParams.set('token', token);

      try {
        const response = await fetch(url);
        const { result } = await response.json();

        cb(result);
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      }
    },
    [createNotification]
  );

  return { verifyEmailAddress };
};

export default useEmailAddresses;
