'use client';

import { node, string } from 'prop-types';
import Link from 'next/link';
import { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';

const OAuthLink = ({ children, className, pathname }) => {
  const searchParams = useSearchParams();

  const filteredParams = useMemo(() => {
    const cloneSearchParams = new URLSearchParams(searchParams);
    cloneSearchParams.delete('code');
    cloneSearchParams.delete('state');
    return cloneSearchParams;
  }, [searchParams]);

  const query = Object.fromEntries(filteredParams);
  const href = useMemo(() => ({ pathname, query }), [pathname, query]);

  return (
    <Link prefetch={false} href={href} className={className}>
      {children}
    </Link>
  );
};

OAuthLink.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
  pathname: string.isRequired,
};

export default OAuthLink;
